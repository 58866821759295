<template>
  <div class="main-news-detail">
    <div class="container-detail container bg-line">
      <div class="go-back">
        <router-link to="/news">
          <img class="go-back__icon" src="../assets/img/icons/arrow-right.svg" alt="" />
          <p class="go-back__title" v-if="language && language['news::back']">{{language['news::back'][locale]}}</p>
        </router-link>
      </div>
      <div class="post-detail">
        <div class="post-detail__bg">
          <img :src="data.image" alt="article-9" />
        </div>
        <h3 class="post-detail__category">{{data?.category_name}}</h3>
        <h1 class="post-detail__title">
          {{data?.title}}
        </h1>
        <!-- <p class="post-detail__date" style="text-transform: uppercase;" v-if="language && language['other::month']">{{data?.created_at?.replace("năm","/").replace("tháng",language['other::month'][locale])}}</p> -->
        <p class="post-detail__date" style="text-transform: uppercase;" v-if="language && language['other::month']">{{data?.created_at?.replace("năm","/").replace("tháng","/")}}</p>
        <div class="post-detail__des">
          {{data?.sub_title}}
        </div>
        <div class="post-detail__content">
          <p v-html="data?.content">
          </p>
        </div>
      </div>
      <div class="other-news">
        <h3 class="other-news__title" v-if="language && language['other:news1']">{{language['other:news1'][locale]}}</h3>
        <div class="other-news__list-item" v-if="newsList?.length > 0">
          <div class="item" v-for="(item, index) in newsList" :key="index">
            <router-link :to="`/detail/${item.id}`">
              <div class="item__avt">
                <img :src="item.image" alt="article-9" />
              </div>
              <h3 class="item__category">{{item[locale]?.category_name}}</h3>
              <h2 class="item__title">
                {{item[locale]?.title}}
              </h2>
              <SeeMore :link="`/detail/${item.id}`"/>
            </router-link>
          </div>
          <!-- <div class="item">
            <router-link to="/detail">
              <div class="item__avt">
                <img src="../assets/img/news/article9.png" alt="article-9" />
              </div>
              <h3 class="item__category">TIN TỨC</h3>
              <h2 class="item__title">
                Eco-industrial park initiative for sustainable industrial zones
                in Vietnam (2014-2019)
              </h2>
              <SeeMore />
            </router-link>
          </div>
          <div class="item">
            <router-link to="/detail">
              <div class="item__avt">
                <img src="../assets/img/news/article9.png" alt="article-9" />
              </div>
              <h3 class="item__category">TIN TỨC</h3>
              <h2 class="item__title">
                Eco-industrial park initiative for sustainable industrial zones
                in Vietnam (2014-2019)
              </h2>
              <SeeMore />
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <Contact :language="language"/>
</template>

<script>
// import { mapMutations } from "vuex";
import SeeMore from "@/components/button/seeMore.vue";
import Contact from "@/components/home/Contact.vue";
import NewsService from '@/services/NewsService';
import { mapGetters } from 'vuex';
import CommonSerive from "@/services/CommonSerive";
export default {
  components: {
    Contact,
    SeeMore,
  },
  data() {
    return {
      data:{},
      newsList:[],
      language:[]
    };
  },
  created(){
    this.detailNews();
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.detailNews();
    this.dataHomea();
  },
  watch:{
    '$route.params.id'() {
      this.detailNews();
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  },
  computed: {
    ...mapGetters(["subActive", "locale"]),
  },
  methods: {
    async detailNews() {
      await NewsService.getdetailNew(this.$route.params.id)
        .then((resp) => {
          this.data.title = resp.data.data.news[this.locale].title;
          this.data.created_at = resp.data.data.news.created_at;
          this.data.sub_title = resp.data.data.news[this.locale].sub_title;
          this.data.content = resp.data.data.news[this.locale].content;
          this.data.image = resp.data.data.news.image;
          this.data.category_name =  resp.data.data.news[this.locale].category_name;
          this.data.id =  resp.data.data.news.id;
          this.newsList = resp.data.data.newsList;
          this.newsList = this.newsList.splice(0,3);
        })
        .catch(() => { });
    },
    scroll() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    async dataHomea() {
      await CommonSerive.getLang()
        .then((resp) => {
          this.language = resp.data.data
        })
        .catch(() => {});
    },
  },
};
</script>
