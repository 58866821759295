<template>
  <div :class="[className ? className : '', 'see-more']" v-if="language && Object.keys(language).length > 0">
    <router-link :to="link ? link : '/'">
      <p>{{language["home::seemore"][locale]}}</p>
      <img src="../../assets/img/icons/see-more.svg" alt="see more" />
      <img class="blue" src="../../assets/img/icons/arrow-blue.svg" alt="see more" />
    </router-link>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
  props: {
    link: null,
    className: null,
    language: null
  },
  computed: {
    ...mapGetters(["locale"]),
  },
};
</script>
